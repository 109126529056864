<template>
    <div>
        <div class="not-found">
            <div class="icon">🚧</div>
            <PageHeader title="Unser Shop befindet sich gerade im Umbau. Wir sind bald wieder da!"/>
        </div>
        <slot name="footer"/>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'

    export default {
        name: 'Construction',

        components: {
            PageHeader
        }
    }
</script>

<style lang="scss" scoped>
    .icon {
        width: 100%;
        text-align: center;
        font-size: 120px;
    }
</style>
